import React from 'react'

import { Button } from '@lumoslabs/lumosity-storybook'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'

import Close from '~/images/close_x.svg'

const Icon = styled(Close)`
  font-size: 19px;
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.oatBase};
  padding-left: min(8vw, 89px);
`

const ButtonQuitWorkout = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation('common')
  return (
    <StyledButton onClick={onClick} leftBadge={<Icon title='Close' />} kind='clean'>
      {t('common:buttons.quitWorkout')}
    </StyledButton>
  )
}

export default ButtonQuitWorkout
