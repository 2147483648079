import { fonts } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import useStreakData from '~/hooks/useStreakData'
import Flame from '~/images/Streak/Flame'

const { Body1Bold } = fonts

type ActiveInterface = {
  isActive: boolean
}

const StreakCounter = (): JSX.Element => {
  const { loading, error, currentStreakLength } = useStreakData()
  const loaded = !(loading || error)

  const isActive = currentStreakLength > 0
  return (
    <Container currentStreakLength={currentStreakLength}>
      <FlameContainer isActive={isActive}>
        <Flame title='Streak' />
      </FlameContainer>
      <Counter isActive={isActive}>
        <Body1Bold>{loaded ? currentStreakLength : 0}</Body1Bold>
      </Counter>
    </Container>
  )
}

const Container = styled.div<{ currentStreakLength: number }>`
  line-height: 40px;
  border-radius: 40px;
  box-shadow: ${(props) => props.theme.card.shadow};
  background: ${(props) => props.theme.colors.whiteBase};
  width: ${(props) => (props.currentStreakLength > 999 ? '130px' : props.currentStreakLength > 99 ? '110px' : '80px')};
  display: flex;
`

const FlameContainer = styled.div<ActiveInterface>`
  flex-grow: 1;
  flex-basis: 50%;
  background: ${(props) => props.theme.colors.coolGray95};
  border-radius: 40px;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.3')};
  display: flex;
  font-size: 21px;
  > svg {
    margin: auto;
  }
`

const Counter = styled.div<ActiveInterface>`
  flex-grow: 1;
  flex-basis: 50%;
  text-align: center;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.inkBase : theme.colors.coolGray62)};
`

export const StreakContainer = styled.div`
  margin-bottom: 40px;
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    display: none;
  }
`
export default StreakCounter
