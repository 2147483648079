import { findIndex } from 'lodash'

import { GetTodaysWorkoutQuery } from '~/graphql/generated/schema'

export const getCurrentWorkoutGamePosition = (queryResult: GetTodaysWorkoutQuery, slug: string) => {
  const { workoutGames } = queryResult.todaysWorkout
  let currentGameIndex = findIndex(workoutGames, (workoutGame) => workoutGame.game.slug === slug)

  const totalTimesInWorkout = workoutGames.filter((workoutGame) => {
    return workoutGame.game.slug === slug
  }).length

  // If game is in workout more than once, set the slot to the next unplayed slot
  if (totalTimesInWorkout > 1) {
    const nextSlot = findIndex(
      workoutGames,
      (workoutGame) => workoutGame.game.slug === slug && !workoutGame.lastGamePlay,
    )

    // If the next instance of the game in the workout has not been played use that slot

    if (nextSlot + 1 > currentGameIndex) {
      currentGameIndex = nextSlot
    } else {
      const reversedWorkoutGames = workoutGames.slice().reverse()
      // find the last slot
      const lastSlot =
        workoutGames.length - findIndex(reversedWorkoutGames, (workoutGame) => workoutGame.game.slug === slug)

      currentGameIndex = lastSlot - 1
    }
  }

  return currentGameIndex + 1
}

export const getTotalGamesPlayed = (queryResult: GetTodaysWorkoutQuery) => {
  const { workoutGames } = queryResult.todaysWorkout
  // Filter out games that do not have a gameplay and only count those that do
  const totalPlayedGames = workoutGames.filter((workoutGame) => {
    return workoutGame.lastGamePlay !== null
  }).length

  return totalPlayedGames
}

export const getTotalGamesCount = (queryResult: GetTodaysWorkoutQuery) => {
  const { workoutGames } = queryResult.todaysWorkout

  return workoutGames.length
}
