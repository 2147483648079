import { config } from '@react-spring/web'

/***** TRANSITIONS *****/
export const workoutCompletionTransition = (workoutFinished: boolean, checkmarkDelay: number) => ({
  from: {
    transform: workoutFinished ? `scale(0)` : `scale(1)`,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignContent: 'center',
  },
  enter: {
    opacity: 1,
    transform: `scale(1)`,
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignContent: 'center',
  },
  leave: { opacity: 0, transform: `scale(0)`, position: 'absolute', display: 'flex', alignContent: 'center' },
  config: config.stiff,
  delay: workoutFinished ? checkmarkDelay : 0,
})
