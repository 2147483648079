import React from 'react'

import { fonts } from '@lumoslabs/lumosity-storybook'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import WorkoutDonut from '~/components/workout/WorkoutDonut'
import { HeaderTypes } from '~/constants'
import { GetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import { getCurrentWorkoutGamePosition, getTotalGamesCount } from '~/utils/workoutUtils'

const { Body2BoldCaps } = fonts

export interface WorkoutHeaderProps {
  headerType: HeaderTypes
  workoutData: GetTodaysWorkoutQuery
}

const WorkoutHeader: React.FC<WorkoutHeaderProps> = ({ headerType, workoutData }) => {
  const { t } = useTranslation('common')
  const donutAnimation = useSpring({
    from: { transform: 'translate3d(250px,0,0) rotateZ(120deg)' },
    to: { transform: 'translate3d(0px,0,0) rotateZ(0deg)' },
  })

  const router = useRouter()
  const slug = router.query?.slug?.[0] ?? ''
  const currentGamePosition = getCurrentWorkoutGamePosition(workoutData, slug)
  const totalGameCount = getTotalGamesCount(workoutData)

  const gameText = t('workoutHeader.workoutCount', {
    currentGamePosition,
    totalGameCount,
  })

  return (
    <WorkoutContainer>
      <WorkoutHeaderCount>{gameText}</WorkoutHeaderCount>
      <animated.div style={{ ...donutAnimation, width: '56px', height: '56px' }}>
        <WorkoutDonut workout={workoutData} headerType={headerType} />
      </animated.div>
    </WorkoutContainer>
  )
}

const WorkoutHeaderCount = styled(Body2BoldCaps)`
  display: flex;
  color: ${({ theme }) => theme.colors.inkBase};
  background-color: ${({ theme }) => theme.colors.coolGray95};
  border-radius: 200px;
  width: 120px;
  height: 24px;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
`

const WorkoutContainer = styled.div`
  padding-right: 6vw;
  display: flex;
  align-items: center;
  z-index: 100;
  position: relative;
  gap: 28px;
  svg {
    width: 56px;
    filter: drop-shadow(0px 2px 100px ${({ theme }) => theme.colors.whiteBase});
  }
`

export default WorkoutHeader
